@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');


@font-face {
  font-family: 'Mluvka-Bold';
  src: url('./fonts/Mluvka-Bold.woff2') format('woff2'),
    url('./fonts/Mluvka-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mluvka-BoldItalic';
  src: url('./fonts/Mluvka-BoldItalic.woff2') format('woff2'),
    url('./fonts/Mluvka-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-LightItalic';
  src: url('./fonts/Mluvka-LightItalic.woff2') format('woff2'),
    url('./fonts/Mluvka-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-ExtraLight';
  src: url('./fonts/Mluvka-ExtraLight.woff2') format('woff2'),
    url('./fonts/Mluvka-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mluvka-Italic';
  src: url('./fonts/Mluvka-Italic.woff2') format('woff2'),
    url('./fonts/Mluvka-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-Light';
  src: url('./fonts/Mluvka-Light.woff2') format('woff2'),
    url('./fonts/Mluvka-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mluvka-ExtraBold';
  src: url('./fonts/Mluvka-ExtraBold.woff2') format('woff2'),
    url('./fonts/Mluvka-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mluvka-ExtraBoldItalic';
  src: url('./fonts/Mluvka-ExtraBoldItalic.woff2') format('woff2'),
    url('./fonts/Mluvka-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-ExtraLightItalic';
  src: url('./fonts/Mluvka-ExtraLightItalic.woff2') format('woff2'),
    url('./fonts/Mluvka-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-MediumItalic';
  src: url('./fonts/Mluvka-MediumItalic.woff2') format('woff2'),
    url('./fonts/Mluvka-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-SemiBoldItalic';
  src: url('./fonts/Mluvka-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/Mluvka-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Mluvka-Medium';
  src: url('./fonts/Mluvka-Medium.woff2') format('woff2'),
    url('./fonts/Mluvka-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mluvka-Regular';
  src: url('./fonts/Mluvka-Regular.woff2') format('woff2'),
    url('./fonts/Mluvka-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Mluvka-SemiBold';
  src: url('./fonts/Mluvka-SemiBold.woff2') format('woff2'),
    url('./fonts/Mluvka-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  font-size: 0.8vw;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  font-size: 1rem;
}

.MainDashboard__RightBody {
  overflow-y: auto;
  height: calc(100vh - 112px);
}

.MainDashboard__RightBody::-webkit-scrollbar-thumb {
  background: $primary-color;
}

.MainDashboard__RightBody::-webkit-scrollbar {
  width: .4rem;
  background-color: #ddd;
}


// .swal-button {
//   transition: .3s;
//   background-color: $primary-color;
//   color: #fff;
//   &--cancel {
//     color: #555;
//     background-color: #efefef;
//   }
//   &:hover,&:focus {
//     background: $secondary-color !important;
//   }
// }
.swal-footer {
  display: flex;
  justify-content: center;
}


.inputBox textarea::-webkit-scrollbar-thumb {
  background: $primary-color;
}

.inputBox textarea::-webkit-scrollbar {
  width: 0;
  background-color: #ddd;
}

.expertiseMain::-webkit-scrollbar-thumb {
  background: $primary-color;
}

.expertiseMain::-webkit-scrollbar {
  width: .4rem;
  background-color: #ddd;
}


.ck.ck-content {

  ol,
  ul {
    padding: 0 1rem !important;
  }
}

.ck-powered-by {
  display: none !important;
}

/* ckeditor-custom.css */
.ck-editor__editable {
  height: 10rem;
}
.header__Right {
  .ck-editor__editable {
    height: 5rem;
  } 
}


.modelBox::-webkit-scrollbar-thumb {
  background: $primary-color;
}

.modelBox::-webkit-scrollbar {
  width: .4rem;
  background-color: #ddd;
}

.lowyerlist::-webkit-scrollbar-thumb {
  background: $primary-color;
}

.lowyerlist::-webkit-scrollbar {
  width: .4rem;
  background-color: #ddd;
}
.nav::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 2rem;
}

.nav::-webkit-scrollbar {
  width: .4rem;
  background-color: #ddd;
  border-radius: 2rem;
}

.galleryMain {
  .ant-upload-list {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
  }
  .ant-upload {
    background: #fff;
  }
  .ant-upload-list-item {
    background: #fff;
  }

  .ant-upload.ant-upload-select,
  .ant-upload-list-item-container {
    width: 100% !important;
    height: 20rem !important;
  }
}

.ant-upload-list-item-error {
  border: 1px solid #d9d9d9 !important;
}


.swal-content {
  text-align: left;
}


.ql-picker-options {
  background: #fff;
}




.p-editor-toolbar {
  display: none;
}

.longhight {
  .p-editor-content {
    height: 50rem !important;
  }
}

.CKEditorsHeight {
  .p-editor-content {
    height: 5rem !important;
  }
}



.ar input, 
.ar select, 
.ar textarea {
  direction: rtl;
}



.tableMain ul.ant-pagination {
  display: none;
}


.dropwMenu ul {
  overflow: hidden;
  max-height: 0;
  transition: .3s;
  top: -0.1rem;
  position: relative;
  border-radius: 0 0 1rem 1rem;
}

.dropwMenu.active ul{
  max-height: max-content;
}
.dropwMenuI {
  padding-right: 1rem;
  border-radius: 2rem;
  position: relative;
  z-index: 2;
}
 
 .activesss .dropwMenuI, 
 .active .dropwMenuI
{
  border:2px solid #3D50DF;
}


.nav ul li a.active::before, .nav ul li a:hover::before {

}
.ant-menu-title-content a {
  display: block;
}